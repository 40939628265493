import React from 'react';
import { Modal, Tabs } from 'antd';
import {
  BankFilled,
  DollarCircleFilled,
  SafetyCertificateFilled,
  QuestionCircleFilled
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import './_security-and-access-modal.scss';

const { TabPane } = Tabs;

const SecurityAndAccessModal = ({ visible, openModal }) => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <Modal
      className="security-and-access-modal"
      visible={visible}
      okText="Entendido"
      onOk={() => openModal(false)}
      onCancel={() => openModal(false)}
      cancelButtonProps={{ className: 'display-none' }}
      okButtonProps={{ className: 'itau-secondary-btn' }}>
      {/* <h2>Seguridad y claves de acceso</h2>
      <span className="text">
        Esta clave es la que usas para acceder a tu portal bancario. Utilizamos estás
        claves para{' '}
        <strong> procesos de consulta de la información de tus destinatarios</strong>, con
        ellas no realizamos otras operaciones.
      </span>
      <div className="ctn-info">
        <span>
          <MailFilled className="icon-email" />
          <span className="text-icon">No se guardan tus claves de acceso</span>
        </span>
        <span>
          <DollarCircleFilled className="icon-money" />
          <span className="text-icon">No se solicitan claves transaccionales</span>
        </span>
        <span>
          <BankFilled className="icon-bank" />
          <span className="text-icon">Tus datos bancarios están protegidos</span>
        </span>
      </div>
      <div className="ctn-info-footer">
        <span className="text">
          Este procedimiento es necesario para extraer tu listado de destinatarios de
          transferencias electrónicas.
        </span>
      </div> */}
      <Tabs onChange={onChange} type="card">
        <TabPane tab="Seguridad" key="1">
          <div className="ctn-info">
            <span className="text-icon">
              <h3>
                <DollarCircleFilled className="icon-money" />{' '}
                <strong>Modo Lectura</strong>
              </h3>
              <br />
              <p style={{ textAlign: 'justify' }}>
                Solo se permite la lectura de datos, no se realiza ninguna acción en tu
                cuenta.
              </p>
            </span>
            <br />
            <span className="text-icon">
              <h3>
                <BankFilled className="icon-bank" /> <strong>Privacidad</strong>
              </h3>
              <br />
              <p style={{ textAlign: 'justify' }}>
                No se guardan ni tus credenciales de acceso ni tu información personal.
              </p>
            </span>
            <br />
            <span className="text-icon">
              <h3>
                <SafetyCertificateFilled className="icon-security" />{' '}
                <strong>Seguridad</strong>
              </h3>
              <br />
              <p style={{ textAlign: 'justify' }}>
                Toda tu información viaja cifrada de extremo a extremo con tal de proteger
                tu seguridad.
              </p>
            </span>
            <br />
            <span className="text-icon">
              <h3>
                <QuestionCircleFilled className="icon-security" />
                <strong>Sobre Boufin</strong>
              </h3>
              <br />
              <p style={{ textAlign: 'justify' }}>
                Boufin fue diseñado desde cero para no guardar ni credenciales ni
                información de las personas. Utilizamos encriptación simétrica con sellos
                de tiempo y capacidades anti manipulación. Todo esto con los más altos
                estándares de nivel bancario.
              </p>
            </span>
          </div>
        </TabPane>
        <TabPane tab="Credenciales">
          <div className="ctn-info">
            <span className="text-icon">
              <h3>
                <SafetyCertificateFilled className="icon-security" />{' '}
                <strong>¿Qué credenciales debo usar?</strong>
              </h3>
              <br />
              <p style={{ textAlign: 'justify' }}>
                Para conectar tu cuenta debes ingresar las credenciales del{' '}
                <b>portal web</b> de tu banco.
              </p>
            </span>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

SecurityAndAccessModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired
};

export default SecurityAndAccessModal;
