import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import Spinner from './components/shared/Spinner';
import { RenderRoute } from './components/shared/RenderRoute';

import { configureAppStore } from './store/configureStore';
import { routes } from './router';
import { history } from './utils/history';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.min.css';
import './index.scss';

export const store = configureAppStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} showDialog>
      <Suspense fallback={<Spinner />}>
        <Switch>
          {routes.map((route, i) => (
            <RenderRoute key={i} {...route} />
          ))}
        </Switch>
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
