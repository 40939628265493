/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setEnableStep } from '../../../../store/step/step.action';
import { setUserInfo } from '../../../../store/user/user.action';

import './_form.scss';

const { Option } = Select;

const prefixSelector = <Form.Item noStyle>+56</Form.Item>;

const PersonalInfoForm = forwardRef((props, ref) => {
  const { disabled } = useSelector((store) => store.step);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { user } = props;

  const handleOnChangeInput = () => {
    console.log(ref);
    if (disabled) dispatch(setEnableStep());
  };

  const onValuesChange = (values) => {
    console.log({ values });
    if ('fullName' in values) {
      dispatch(setUserInfo({ ...user, fullName: values.fullName }));
    }
  };

  return (
    <Form
      form={form}
      ref={ref}
      id="personal-info-form"
      layout="vertical"
      size="large"
      onValuesChange={onValuesChange}>
      <Row>
        <Col span={24} className="pi-form-ctn">
          <h2 className="title">Datos personales</h2>
          <Row>
            <Col span={11} className="display-info">
              {/* <div className="title">Nombre</div> */}
              <Form.Item
                label="Nombre"
                name="fullName"
                className="input-name"
                type="string">
                <Input onChange={handleOnChangeInput} maxLength={50} />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={11} className="display-info">
              <div className="title">Rut</div>
              <div className="subtitle">{user.rut}</div>
            </Col>
          </Row>
        </Col>
        <Col span={11} className="pi-form-ctn">
          <h2 className="title">¿Dónde te contactamos?</h2>
          <Form.Item
            label="Número de teléfono"
            name="phone"
            className="input-phone"
            type="number"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu teléfono'
              }
            ]}>
            <Input
              addonBefore={prefixSelector}
              onChange={handleOnChangeInput}
              maxLength={9}
            />
          </Form.Item>
        </Col>
        <Col span={2} />
        <Col span={11} className="pi-form-ctn">
          <h2 className="title">¿Cuál es tu renta líquida mensual?</h2>
          <Form.Item
            name="renta"
            label="Renta promedio"
            rules={[
              {
                required: true,
                message: 'Por favor selecciona tu renta'
              }
            ]}>
            <Select allowClear>
              <Option value="1">$0 - $999.999</Option>
              <Option value="2">$999.999 - $1.299.999</Option>
              <Option value="3">$1.300.000 - $1.699.999</Option>
              <Option value="4">$1.700.000 - $2.999.999</Option>
              <Option value="5">$3.000.000 - Otro monto</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11} className="pi-form-ctn">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu email'
              },
              {
                type: 'email',
                message: 'Por favor ingresa un email'
              }
            ]}>
            <Input onChange={handleOnChangeInput} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

PersonalInfoForm.displayName = 'PersonalInfoForm';

export default PersonalInfoForm;
