import rutJS from 'rut.js';

export const customRutValidation = () => ({
  validator(rule, value) {
    if (!value || rutJS.validate(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Por favor ingresa un rut válido');
  }
});

export function formatRut(rut, clean = true) {
  let formatRut = clean ? rutJS.format(rutJS.clean(rut)) : rutJS.format(rut);

  if (formatRut.length === 1 && formatRut === '-') formatRut = '';

  return formatRut;
}

export function arrSelectedProducts(products, bank) {
  const userProducts = products.filter((p) => !p.code.includes('new'));
  return userProducts.map((p) => {
    return {
      name: p.description,
      identifier: p.value,
      bankName: bank.name
    };
  });
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';

  const value = s.charAt(0).toUpperCase() + s.slice(1);
  return value.replace('-', ' ');
}
