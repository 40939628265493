import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleFilled } from '@ant-design/icons';

import './_product-selection-new.scss';

const NewBankingProductsNew = ({ productCodes, handleSelectionProduct }) => {
  const [BankLogo, setBankLogo] = useState('');

  useEffect(() => {
    const getLogoHeader = async () => {
      const logo = await import(
        `../../../../assets/img/logo/${process.env.REACT_APP_BANK}/logo.png`
      );

      setBankLogo(logo.default);
    };

    getLogoHeader();
  }, []);

  const handleSelected = (code, value) => {
    handleSelectionProduct({ code, description: value, value });
  };

  return (
    <Row>
      <Col span={24} className="product-selection-new-ctn">
        <Row>
          <Col span={24} className="ctn">
            <h2 className="title">
              <img className="bank-icon" src={BankLogo} alt="bank icon" />
              Productos adicionales
            </h2>
            <Row gutter={[16, 16]}>
              <Col xs={22} lg={12} className="ctn">
                <div
                  className={`${productCodes.includes('newcorriente') && 'active'} item`}
                  onClick={() => handleSelected('newcorriente', 'Cuenta Corriente')}
                  aria-hidden>
                  Cuenta Corriente
                  {productCodes.includes('newcorriente') && <CheckCircleFilled />}
                </div>
                <div
                  className={`${productCodes.includes('newvista') && 'active'} item`}
                  onClick={() => handleSelected('newvista', 'Cuenta Vista')}
                  aria-hidden>
                  Cuenta Vista
                  {productCodes.includes('newvista') && <CheckCircleFilled />}
                </div>
                {/* <div
                  className={`${productCodes.includes('newcomercial') && 'active'} item`}
                  onClick={() => handleSelected('newcomercial', 'Crédito de Comercial')}
                  aria-hidden>
                  Crédito de Comercial
                  {productCodes.includes('newcomercial') && <CheckCircleFilled />}
                </div> */}
              </Col>
              <Col xs={22} lg={12} className="ctn">
                <div
                  className={`${productCodes.includes('newconsumo') && 'active'} item`}
                  onClick={() => handleSelected('newconsumo', 'Crédito de consumo')}
                  aria-hidden>
                  Crédito de consumo
                  {productCodes.includes('newconsumo') && <CheckCircleFilled />}
                </div>
                <div
                  className={`${
                    productCodes.includes('newhipotecario') && 'active'
                  } item`}
                  onClick={() => handleSelected('newhipotecario', 'Crédito Hipotecario')}
                  aria-hidden>
                  Crédito Hipotecario
                  {productCodes.includes('newhipotecario') && <CheckCircleFilled />}
                </div>
              </Col>
              <Col xs={22} lg={12} className="ctn">
                <div
                  style={{ marginTop: '2rem' }}
                  className={`${productCodes.includes('noproducto') && 'active'} item`}
                  onClick={() => handleSelected('noproducto', 'No producto')}
                  aria-hidden>
                  No quiero contratar otro producto
                  {productCodes.includes('noproducto') && <CheckCircleFilled />}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

NewBankingProductsNew.propTypes = {
  handleSelectionProduct: PropTypes.func.isRequired,
  productCodes: PropTypes.array.isRequired
};

export default NewBankingProductsNew;
