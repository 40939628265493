import { createSlice } from '@reduxjs/toolkit';

import SantanderLogo from '../../assets/img/banks/LogoSantander.svg';
import BancoChileLogo from '../../assets/img/banks/LogoChile.svg';
import BCILogo from '../../assets/img/banks/LogoBCI.svg';
import ScotiabankLogo from '../../assets/img/banks/LogoScotia.svg';
// import FalabellaLogo from '../../assets/img/banks/LogoFalabella.svg';
import FalabellaLogoDisabled from '../../assets/img/banks/LogoFalabellaDisabled.svg';
import EstadoLogo from '../../assets/img/banks/LogoEstado.svg';
// import RipleyLogo from '../../assets/img/banks/LogoRipley.svg';
import RipleyLogoDisabled from '../../assets/img/banks/LogoRipleyDisabled.svg';
// import SecurityLogo from '../../assets/img/banks/LogoSecurity.svg';
import SecurityLogoDisabled from '../../assets/img/banks/LogoSecurityDisabled.svg';
// import ConsorcioLogo from '../../assets/img/banks/LogoConsorcio.svg';
import ConsorcioLogoDisabled from '../../assets/img/banks/LogoConsorcioDisabled.svg';
// import BiceLogo from '../../assets/img/banks/LogoBice.svg';
import BiceLogoDisabled from '../../assets/img/banks/LogoBiceDisabled.svg';
import NiuBankLogoDisabled from '../../assets/img/banks/LogoNiubankDisabled.svg';
import ItauLogo from '../../assets/img/banks/LogoItau.svg';

const initialState = {
  banks: [
    {
      id: 'banco-bci',
      name: `Banco BCI`,
      code: '016',
      icon: BCILogo,
      active: true
    },
    {
      id: 'banco-itau',
      name: `Banco Itau`,
      code: '039',
      icon: ItauLogo,
      active: true
    },
    {
      id: 'banco-santander',
      name: `Banco Santander`,
      code: '037',
      icon: SantanderLogo,
      active: true
    },
    {
      id: 'banco-chile',
      name: `Banco de Chile`,
      code: '001',
      icon: BancoChileLogo,
      active: true
    },
    {
      id: 'banco-scotiabank',
      name: `Banco Scotiabank`,
      code: '004',
      icon: ScotiabankLogo,
      active: true
    },
    {
      id: 'banco-estado',
      name: `Banco Estado`,
      code: '006',
      icon: EstadoLogo,
      active: true
    },
    {
      id: 'banco-falabella',
      name: `Banco Falabella`,
      code: '005',
      icon: FalabellaLogoDisabled,
      active: false
    },
    {
      id: 'banco-bice',
      name: `Banco Bice`,
      code: '007',
      icon: BiceLogoDisabled,
      active: false
    },
    {
      id: 'banco-consorcio',
      name: `Banco Consorcio`,
      code: '008',
      icon: ConsorcioLogoDisabled,
      active: false
    },
    {
      id: 'banco-ripley',
      name: `Banco Ripley`,
      code: '009',
      icon: RipleyLogoDisabled,
      active: false
    },
    {
      name: `Banco Security`,
      code: '010',
      icon: SecurityLogoDisabled,
      active: false
    },
    {
      name: `NiuBank`,
      code: '010',
      icon: NiuBankLogoDisabled,
      active: false
    }
  ]
};

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder.addDefaultCase(() => {})
});

export default banksSlice.reducer;
