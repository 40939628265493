import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// import { InfoCircleFilled } from '@ant-design/icons';

import EntitySelectionList from '../../../components/Stepper/EntitySelection/List';
import { setDisableStep, setEnableStep } from '../../../store/step/step.action';
import './_entity-selection.scss';

const EntitySelection = forwardRef((props, ref) => {
  const { bankSelected } = useSelector((store) => store.extraction);
  const dispatch = useDispatch();
  const isPrivateSite = queryString.parse(useLocation().search).private;

  useEffect(() => {
    if (!Object.keys(bankSelected).length) {
      dispatch(setDisableStep());
    } else {
      dispatch(setEnableStep());
    }
  }, [dispatch, bankSelected]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => ({ error: false })
  }));

  const span = isPrivateSite ? { span: 24 } : { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 };

  return (
    <>
      <Row>
        <Col {...span} className="entity-selection-header">
          <h1>Selecciona tu banco </h1>
          <h2>
            Para acceder a la información de tus productos nos conectaremos con tu entidad
            bancaria
          </h2>
        </Col>
      </Row>
      <Row>
        <Col {...span} className="entity-selection-ctn">
          <EntitySelectionList />
        </Col>
      </Row>
    </>
  );
});

EntitySelection.displayName = 'EntitySelection';

export default EntitySelection;
