import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { setUserInfo } from '../user/user.action';

export const setSelectedBank = createAction('extraction/setSelectedBank');
export const setProductCodes = createAction('extraction/setProductCodes');
export const setSelectedProducts = createAction('extraction/setSelectedProducts');
export const setSignedDocuments = createAction('extraction/setSignedDocuments');

let REACT_APP_API_DUMMY = false;
if (
  process.env.REACT_APP_API_DUMMY &&
  process.env.REACT_APP_API_DUMMY.toLowerCase() === 'true'
) {
  REACT_APP_API_DUMMY = true;
}

export const fetchUserBankingProducts = createAsyncThunk(
  'extraction/fetchUserBankingProducts',
  async ({ rut, password }, { dispatch, getState, requestId, rejectWithValue }) => {
    const { bankSelected, currentRequestId, loading } = getState().extraction;
    const baseUrl = process.env.REACT_APP_API_URL;

    if (!loading || requestId !== currentRequestId) return;

    try {
      let response;

      if (REACT_APP_API_DUMMY) {
        response = {
          data: {
            status: 200,
            profile: {},
            results: {
              hipotecario: [
                { id: '500007865441', humanId: '5-000-07-86544-1' },
                { id: '500009208944', humanId: '5-000-09-20894-4' },
                { id: '500010528928', humanId: '5-000-10-52892-8' }
              ],
              consumo: []
            },
            errors: []
          }
        };
      } else {
        response = await axios.post(`${baseUrl}/bank/products`, {
          entityId: bankSelected.id,
          rut,
          password
        });
      }

      dispatch(setUserInfo({ rut }));
      return response.data;
    } catch (error) {
      console.log({ error: error.response.data });

      if (error.response.status === 401 && error.response.data.taskStatusCode === 401) {
        return rejectWithValue('El usuario o contraseña son incorrectos');
      } else if (error.response.status === 401) {
        return rejectWithValue(
          'No posees los permisos necesarios para realizar esta acción'
        );
      }

      if (error.response.status === 403) {
        return rejectWithValue(
          'Usuario se encuentra bloqueado vuelva a intentarlos en algunas horas'
        );
      } else if (error.response.status === 429) {
        return rejectWithValue(
          'Se esta ejecutando un proceso de extraccion, por favor espere un momento'
        );
      }
      return rejectWithValue('Error al obtener la información de los productos');
    }
  }
);
