import { createSlice } from '@reduxjs/toolkit';

import {
  setSelectedBank,
  fetchUserBankingProducts,
  setProductCodes,
  setSelectedProducts,
  setSignedDocuments
} from './extraction.action';

const initialState = {
  bankSelected: {},
  currentRequestId: null,
  bankProducts: {},
  productCodes: [],
  selectedProducts: [],
  loading: false,
  signedDocuments: false,
  error: undefined
};

const extractionSlice = createSlice({
  name: 'extraction',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(setSelectedBank, (state, action) => {
        // const { name, code, active, icon } = action.payload;
        state.bankSelected = action.payload;
      })
      .addCase(fetchUserBankingProducts.pending, (state, action) => {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchUserBankingProducts.fulfilled, (state, action) => {
        const { requestId } = action.meta;

        if (state.currentRequestId === requestId) {
          state.loading = false;
          state.bankProducts = action.payload.results;
          state.currentRequestId = undefined;
          state.error = undefined;
        }
      })
      .addCase(fetchUserBankingProducts.rejected, (state, action) => {
        const { requestId } = action.meta;

        if (state.currentRequestId === requestId) {
          state.loading = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(setProductCodes, (state, action) => {
        const product = action.payload.product;
        const existCode = state.productCodes.some((p) => p === product.code);

        if (existCode) {
          const newProductCodes = state.productCodes.filter((c) => c !== product.code);
          state.productCodes = newProductCodes;
        } else {
          state.productCodes = [...state.productCodes, action.payload.product.code];
        }
      })
      .addCase(setSelectedProducts, (state, action) => {
        const product = action.payload.product;
        const existProduct = state.selectedProducts.some((p) => p.code === product.code);

        if (existProduct) {
          const newSelectedProduct = state.selectedProducts.filter(
            (c) => c.code !== product.code
          );
          state.selectedProducts = newSelectedProduct;
        } else {
          state.selectedProducts = [...state.selectedProducts, action.payload.product];
        }
      })
      .addCase(setSignedDocuments, (state) => {
        state.signedDocuments = !state.signedDocuments;
      })
      .addDefaultCase(() => {})
});

export default extractionSlice.reducer;
