import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import ProductSelectionList from '../../../components/Stepper/ProductSelection/List';
import { setDisableStep, setEnableStep } from '../../../store/step/step.action';
import {
  setProductCodes,
  setSelectedProducts
} from '../../../store/extracion/extraction.action';
import { capitalize } from '../../../utils/misc';

import './_product-selection.scss';

const { Text } = Typography;

const ProductSelection = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { productCodes, selectedProducts, bankSelected, bankProducts } = useSelector(
    (store) => store.extraction
  );

  useEffect(() => {
    dispatch(setDisableStep());
  }, [dispatch]);

  useEffect(() => {
    !selectedProducts.length ? dispatch(setDisableStep()) : dispatch(setEnableStep());
  }, [dispatch, selectedProducts]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return { error: false };
    }
  }));

  const handleSelectionProduct = ({ code, description, value }) => {
    const product = {
      code: code,
      description: description,
      value: value
    };

    dispatch(setSelectedProducts({ product }));
    dispatch(setProductCodes({ product }));
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="product-selection-header">
          <h1>Estos son tus deudas vigentes en {bankSelected.name}</h1>
          <Text type="secondary" className="subtitle">
            Selecciona las deudas que quieras consolidad y trasladar a{' '}
            {capitalize(process.env.REACT_APP_BANK)}
            {capitalize(process.env.REACT_APP_BANK)}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="ctn-product-selection">
          <ProductSelectionList
            bankSelected={bankSelected}
            bankProducts={bankProducts}
            selectedProducts={selectedProducts}
            productCodes={productCodes}
            handleSelectionProduct={handleSelectionProduct}
          />
        </Col>
      </Row>
    </>
  );
});

ProductSelection.displayName = 'ProductSelection';

export default ProductSelection;
