import React, { forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Tooltip, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloudDownloadOutlined, InfoCircleFilled } from '@ant-design/icons';

import { fetchDownloadingMandate } from '../../../store/sign/sign.action';

import signingProcessImg from '../../../assets/img/signing-process.svg';
import mandateImg from '../../../assets/img/mandate.svg';

import './_sign.scss';

const Sign = forwardRef((props, ref) => {
  const { signedDocuments } = useSelector((store) => store.extraction);
  const { downloading } = useSelector((store) => store.sign);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return { error: false, data: null };
    }
  }));

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="sign-header">
          {signedDocuments ? (
            <Row>
              <Col span={24} className="signing-process-text">
                <img src={mandateImg} alt="signing process" />
              </Col>
              <Col span={18}>
                <h3 className="thanks">¡Gracias!</h3>
                <h3 className="subtitle-text">
                  Tu solicitud se completó de forma exitosa, Ahora puedes descargar tu
                  mandato de consolidación.
                </h3>
                <span
                  id="download-mandate"
                  onClick={() => dispatch(fetchDownloadingMandate())}
                  aria-hidden>
                  <Spin spinning={downloading}>
                    <CloudDownloadOutlined /> Descargar Mandato
                  </Spin>
                </span>
                <p className="info-text">
                  Pronto nos pondremos en contacto contigo, para entregarte una oferta de
                  consolidación.
                </p>
              </Col>
            </Row>
          ) : (
            <h1>
              ¡Un último paso!
              <Tooltip title="Un mandato de portabilidad faculta a la nueva institución financiera para realizar todos los pagos, comunicaciones o requerimientos correspondientes, en nombre y representación del cliente.">
                <InfoCircleFilled className="tooltip-info" />
              </Tooltip>
              <h2>Firma tu mandato para finalizar la solicitud </h2>
            </h1>
          )}
        </Col>
      </Row>
      {!signedDocuments && (
        <Row>
          <Col xs={24} lg={20} className="sign-ctn">
            <Row>
              <Col xs={10} lg={6} className="signing-process-text">
                <img src={signingProcessImg} alt="signing process" />
              </Col>
              <Col xs={24} lg={18}>
                <h3 className="signing-process-text">
                  Para firmar electrónicamente,{' '}
                  <strong>te dirigiremos a el sitio de Mobisigner.</strong>
                </h3>
                <h3 className="signing-process-text">
                  Ahí podrás firmar usando tu clave única del estado o respondiendo
                  algunas preguntas.
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
});

Sign.displayName = 'Sign';

export default Sign;
